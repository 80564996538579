import App from './App';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import en from 'antd/locale/en_US';
import jp from 'antd/locale/ja_JP';
import Storage from './utils/storage';
import { DefaultLanguage } from './constants/language';
import './locales/i18n';

const container = document.getElementById('root');
// eslint-disable-next-line
const root = createRoot(container!);
const lang = Storage.getLanguage();

root.render(
  <ConfigProvider locale={lang == DefaultLanguage ? en : jp}>
    <App />
  </ConfigProvider>,
);
