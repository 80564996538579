/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Image, Text, useToast } from '@chakra-ui/react';
import cn from 'classnames';
import { useState } from 'react';
import { icGoogle } from 'src/assets/icon';
import { LogoSingle } from 'src/assets/images';
import AppButton from 'src/components/AppButton';
import { AuthLayout } from 'src/layouts';
import rf from 'src/requests/RequestFactory';
import styles from 'src/styles/page/Login.module.scss';
// import EmailForm from './form/EmailForm';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  LoginBasicAuthen,
  RequestResetPasswordBasicAuthen,
} from 'src/requests/AuthRequest';
import { setUserAuth } from 'src/store/myAccount';
import EmailForm from './form/EmailForm';
import LoginForm from './form/LoginForm';
import ResetForm from './form/ResetForm';

enum Step {
  Step1 = 'Enter email',
  Step2 = 'Enter email and password',
  Step3 = 'Reset passsword',
}

function LogIn() {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [step, setStep] = useState<Step>(Step.Step1);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingResetPassword, setLoadingResetPassword] =
    useState<boolean>(false);

  const [form, setForm] = useState<LoginBasicAuthen>({
    email: '',
    password: '',
  });

  const onLoginGoogle = async () => {
    try {
      const res = await rf.getRequest('AuthRequest').getLoginGoogle();
      const url: string = res.data.url;
      window.location.href = url;
    } catch (e) {
      console.error(e, 'Error');
    }
  };

  const onSubmitStep1 = (values: any) => {
    setForm({ ...form, ...values });
    setStep(Step.Step2);
  };

  const onSubmitStep2 = async (values: any) => {
    try {
      setLoading(true);
      const res = await rf.getRequest('AuthRequest').loginBasic(values);

      const {
        data: { access_token },
      } = res;

      dispatch(setUserAuth({ accessToken: access_token }));
      navigate('/');
    } catch (error) {
      toast({
        title: 'Login failed!',
        description:
          'Invalid login credentials. Please check your email and password and try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmitStep3 = async (values: RequestResetPasswordBasicAuthen) => {
    try {
      setLoadingResetPassword(true);

      await rf.getRequest('AuthRequest').requestResetPassword(values);

      toast({
        title: 'Reset Password!',
        description:
          'Your password has been successfully reset and sent to your email. Please check your inbox for the new password and use it to login.',
        status: 'success',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Reset Password failed!',
        description:
          'Reset password error. Please check your email and try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoadingResetPassword(false);
    }
  };

  const onResetPassword = () => {
    setStep(Step.Step3);
  };

  const _renderEmailPart = () => {
    return (
      <>
        <EmailForm onSubmit={onSubmitStep1} />

        <Box className={cn(styles.divide)} py={3} position={'relative'}>
          <Text m={0} className={cn(styles.content)}>
            OR
          </Text>
        </Box>
        {/* <Text className={cn(styles.title)} pt={4} textAlign={'center'}>
          Welcome back
        </Text> */}
        <AppButton
          onClick={onLoginGoogle}
          borderRadius={'6px'}
          size="md"
          width={'full'}
          className={cn(styles.btn_login)}
          variant="outline"
          mt={8}
          mb={3}
        >
          <Image src={icGoogle} pr={2} alt="logo" />
          Continue with Google
        </AppButton>
        {/* <AppButton
          borderRadius={'6px'}
          size={{ base: 'md', sm: 'lg' }}
          variant="outline"
          className={cn(styles.btn_login)}
          width={'full'}
          mb={3}
        >
          <Image src={icMicrosoft} pr={2} alt="logo" />
          Continue with Microsoft Account
        </AppButton>
        <AppButton
          borderRadius={'6px'}
          variant="outline"
          size={{ base: 'md', sm: 'lg' }}
          className={cn(styles.btn_login)}
          width={'full'}
          mb={3}
        >
          <Image src={icApple} pr={2} alt="logo" />
          Continue with Apple
        </AppButton> */}
      </>
    );
  };

  const _renderPasswordPart = () => {
    return (
      <LoginForm
        values={form}
        onSubmit={onSubmitStep2}
        isLoading={loading}
        onResetPassword={onResetPassword}
      />
    );
  };

  const _renderResetPasswordPart = () => {
    return (
      <ResetForm onSubmit={onSubmitStep3} isLoading={loadingResetPassword} />
    );
  };

  const showUI = (step: Step) => {
    switch (step) {
      case Step.Step1:
        return _renderEmailPart();

      case Step.Step2:
        return _renderPasswordPart();

      case Step.Step3:
        return _renderResetPasswordPart();

      default:
        return _renderEmailPart();
    }
  };

  return (
    <AuthLayout>
      <Box className={cn(styles.containerWrapper)}>
        <Image
          mx={'auto'}
          w={{ base: '40px', sm: 'auto' }}
          src={LogoSingle}
          pr={2}
          alt="logo"
        />
        {showUI(step)}
      </Box>
    </AuthLayout>
  );
}

export default LogIn;
