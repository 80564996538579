/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { emailValidation } from 'src/common/validationForm';
import AppButton from 'src/components/AppButton';
import styles from 'src/styles/page/Login.module.scss';
import * as yup from 'yup';
import EmailSvg from '../../../assets/images/email.svg';

type Props = {
  onSubmit: (values: any) => void;
};

const schema = yup.object().shape({
  email: emailValidation,
});

const EmailForm = ({ onSubmit }: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text className={cn(styles.title)} pt={4} textAlign={'center'}>
          Welcome back
        </Text>
        <FormControl isInvalid={!!errors.email}>
          <InputGroup
            size="md"
            className={cn(styles.inputGroup)}
            mt={8}
          >
            <InputLeftElement
              className={cn(styles.inputIcon)}
              pointerEvents="none"
            >
              <img src={EmailSvg} alt="email icon" />
            </InputLeftElement>
            <Input
              className={cn(styles.input)}
              variant="outline"
              placeholder="Email address"
              {...register('email')}
            />
          </InputGroup>
          <FormErrorMessage>
            {errors?.email?.message as string}
          </FormErrorMessage>
        </FormControl>

        <AppButton
          borderRadius={'8px'}
          size="md"
          width={'full'}
          mt={6}
          variant="primary"
          mb={3}
          type="submit"
          className={cn(styles.btnSubmit, styles.btnContinue)}
        >
          Continue
        </AppButton>

        {/* <Text className={cn(styles.description)}>
          Don’t have an account?{' '}
          <Link className={cn(styles.link)} to={'/sign-up'}>
            Sign up
          </Link>
        </Text> */}
      </form>
    </Box>
  );
};

export default EmailForm;
