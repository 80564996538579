import React from 'react';
import { ISvg } from 'src/types/Svg.type';

const IcOptionBot: React.FC<ISvg> = ({
  linearGradient = ['#74C0FC', '#184FC7'],
}) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15.0952"
        cy="14.9999"
        r="15"
        fill="url(#paint0_linear_616_1949)"
      />
      <foreignObject width="100%" height="100%">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.03432 3.19004C4.13516 2.8155 4.18559 2.62823 4.27845 2.60724C4.30137 2.60206 4.32515 2.60206 4.34806 2.60724C4.44093 2.62823 4.49135 2.8155 4.59219 3.19004L4.68916 3.55018C4.91837 4.40146 5.03297 4.8271 5.30065 5.14711C5.37266 5.23319 5.45216 5.3127 5.53824 5.3847C5.85825 5.65238 6.28389 5.76698 7.13517 5.99619L7.49531 6.09316C7.86985 6.194 8.05712 6.24442 8.07811 6.33729C8.08329 6.3602 8.08329 6.38399 8.07811 6.4069C8.05712 6.49977 7.86985 6.55019 7.49531 6.65103L7.13517 6.748C6.28389 6.9772 5.85825 7.09181 5.53824 7.35949C5.45216 7.43149 5.37266 7.511 5.30065 7.59708C5.03297 7.91709 4.91837 8.34273 4.68916 9.19401L4.59219 9.55415C4.49135 9.92869 4.44093 10.116 4.34806 10.137C4.32515 10.1421 4.30137 10.1421 4.27845 10.137C4.18559 10.116 4.13516 9.92869 4.03432 9.55415L3.93735 9.19401C3.70815 8.34273 3.59354 7.91709 3.32586 7.59708C3.25386 7.511 3.17435 7.43149 3.08827 7.35949C2.76826 7.09181 2.34262 6.9772 1.49134 6.748L1.1312 6.65103C0.756662 6.55019 0.569393 6.49977 0.5484 6.4069C0.543219 6.38399 0.543219 6.3602 0.5484 6.33729C0.569393 6.24442 0.756662 6.194 1.1312 6.09316L1.49134 5.99619C2.34262 5.76698 2.76826 5.65238 3.08827 5.3847C3.17435 5.3127 3.25386 5.23319 3.32586 5.14711C3.59354 4.8271 3.70815 4.40146 3.93735 3.55018L4.03432 3.19004Z"
              fill="white"
            />
            <path
              d="M11.0182 0.572085C11.0577 0.425372 11.0775 0.352016 11.1048 0.330017C11.1442 0.298365 11.2003 0.298365 11.2397 0.330017C11.2671 0.352016 11.2868 0.425372 11.3263 0.572085C11.4168 0.908243 11.4621 1.07632 11.5426 1.21867C11.658 1.42257 11.8267 1.59124 12.0306 1.70663C12.1729 1.78718 12.341 1.83244 12.6772 1.92295C12.8239 1.96245 12.8973 1.9822 12.9193 2.00957C12.9509 2.04895 12.9509 2.10504 12.9193 2.14442C12.8973 2.17179 12.8239 2.19154 12.6772 2.23105C12.341 2.32155 12.1729 2.36681 12.0306 2.44737C11.8267 2.56275 11.658 2.73142 11.5426 2.93532C11.4621 3.07767 11.4168 3.24575 11.3263 3.58191C11.2868 3.72862 11.2671 3.80198 11.2397 3.82397C11.2003 3.85563 11.1442 3.85563 11.1048 3.82397C11.0775 3.80198 11.0577 3.72862 11.0182 3.58191C10.9277 3.24575 10.8825 3.07767 10.8019 2.93532C10.6865 2.73142 10.5178 2.56275 10.314 2.44737C10.1716 2.36681 10.0035 2.32155 9.66736 2.23105C9.52065 2.19154 9.44729 2.17179 9.42529 2.14442C9.39364 2.10504 9.39364 2.04895 9.42529 2.00957C9.44729 1.9822 9.52065 1.96245 9.66736 1.92295C10.0035 1.83244 10.1716 1.78718 10.314 1.70663C10.5178 1.59124 10.6865 1.42257 10.8019 1.21867C10.8825 1.07632 10.9277 0.908243 11.0182 0.572085Z"
              fill="white"
            />
            <path
              d="M11.0182 9.1623C11.0577 9.01558 11.0775 8.94223 11.1048 8.92023C11.1442 8.88858 11.2003 8.88858 11.2397 8.92023C11.2671 8.94223 11.2868 9.01558 11.3263 9.16229C11.4168 9.49845 11.4621 9.66653 11.5426 9.80888C11.658 10.0128 11.8267 10.1815 12.0306 10.2968C12.1729 10.3774 12.341 10.4226 12.6772 10.5132C12.8239 10.5527 12.8973 10.5724 12.9193 10.5998C12.9509 10.6392 12.9509 10.6953 12.9193 10.7346C12.8973 10.762 12.8239 10.7818 12.6772 10.8213C12.341 10.9118 12.1729 10.957 12.0306 11.0376C11.8267 11.153 11.658 11.3216 11.5426 11.5255C11.4621 11.6679 11.4168 11.836 11.3263 12.1721C11.2868 12.3188 11.2671 12.3922 11.2397 12.4142C11.2003 12.4458 11.1442 12.4458 11.1048 12.4142C11.0775 12.3922 11.0577 12.3188 11.0182 12.1721C10.9277 11.836 10.8825 11.6679 10.8019 11.5255C10.6865 11.3216 10.5178 11.153 10.314 11.0376C10.1716 10.957 10.0035 10.9118 9.66736 10.8213C9.52065 10.7818 9.44729 10.762 9.42529 10.7346C9.39364 10.6953 9.39364 10.6392 9.42529 10.5998C9.44729 10.5724 9.52065 10.5527 9.66736 10.5132C10.0035 10.4226 10.1716 10.3774 10.314 10.2968C10.5178 10.1815 10.6865 10.0128 10.8019 9.80888C10.8825 9.66653 10.9277 9.49845 11.0182 9.1623Z"
              fill="white"
            />
          </svg>
        </div>
      </foreignObject>
      <defs>
        <linearGradient
          id="paint0_linear_616_1949"
          x1="7.59521"
          y1="-6.08116e-05"
          x2="22.5952"
          y2="29.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={linearGradient[0]} />
          <stop offset="1" stopColor={linearGradient[1]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IcOptionBot;
