/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Flex,
  IconButton,
  Image,
  InputGroup,
  InputRightElement,
  ListItem,
  SimpleGrid,
  Text,
  Textarea,
  List,
  useTheme,
} from '@chakra-ui/react';
import rf from 'src/requests/RequestFactory';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { icGuideBot } from 'src/assets/icon';
import { HomeGrid_V2, LOADING_CHAT } from 'src/constants';
import styles from 'src/styles/page/Home.module.scss';
import chatStyles from 'src/styles/page/Chat.module.scss';
import { useAppSelector } from 'src/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { getNavigateRoute } from 'src/utils/chat';
import { IcSend, IcOptionBot } from 'src/components/icons';
import { ERole, IChatGptDetail, IMessage } from 'src/types';
import MessageCard from 'src/components/MessageCard';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();
  const [input, setInput] = useState<string>('');
  const { currentAI } = useAppSelector((state) => state.chat);
  const { bot_id } = useParams();
  const theme = useTheme();

  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<IChatGptDetail>({
    _id: '',
    temperature: 0,
    model_type: '',
    source: '',
    user_id: '',
    messages: [],
  });

  const addNewChat = async () => {
    setLoading(true);
    try {
      const newMessageByUser = [
        {
          role: ERole.USER,
          content: input,
        },
      ];
      setDetailData((pre: IChatGptDetail) => {
        return {
          ...pre,
          messages: [
            ...newMessageByUser,
            {
              role: ERole.ASSISTANT,
              content: LOADING_CHAT,
            },
          ],
        };
      });
      const contentMessage = input;
      setInput('');
      const res = await rf.getRequest('ChatRequest').createChatConversations({
        temperature: 0.7,
        bot_id: currentAI.bot_id,
      });
      const { inserted_id } = res.data;
      await rf.getRequest('ChatRequest').sendChatMessage({
        content: contentMessage,
        conversation_id: inserted_id,
      });

      navigate(
        getNavigateRoute(currentAI.source, currentAI.bot_id, inserted_id),
      );
    } catch (e) {
      console.log(e, 'Error');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (ref) {
      ref.current.focus();
    }
  }, [ref, bot_id]);

  return (
    <Flex
      flexDirection={'column'}
      position={'relative'}
      className={cn(styles.homeContainer)}
    >
      {detailData.messages.length ? (
        <Box
          className={cn(chatStyles.chatContainer)}
          w={{ base: 'full', lg: 'full' }}
          height="100%"
        >
          <Box position={'relative'} className={cn(chatStyles.chatWrapper)}>
            <Box pt={'30px'} px={'20px'} className={cn(chatStyles.chatContent)}>
              {detailData?.messages?.map((el: IMessage, index) => (
                <MessageCard
                  message={el}
                  key={detailData._id + index + el.role}
                />
              ))}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box flex={1} overflow={'auto'} className={cn(styles.mainWrapper)}>
          <Box pb={{ base: '50px', sm: 0 }} className={cn(styles.topWrapper)}>
            <Box className={cn(styles.guideBotWrapperBtn)}>
              <button className={cn(styles.guideBotBtn)}>
                <IcOptionBot
                  linearGradient={[
                    theme.colors.brand[300],
                    theme.colors.brand[600],
                  ]}
                />
                <Text className={cn(styles.guideBotText)}>
                  {t('home.title')}
                </Text>
              </button>
            </Box>
            <Text className={cn(styles.title)}>{t('home.title')}</Text>
            <Text className={cn(styles.subTitle)}>{t('home.subTitle')}</Text>

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={'25px'}>
              {HomeGrid_V2.map((data, index) => (
                <Box key={index} display={'flex'} justifyContent={'center'}>
                  <Flex
                    px={5}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={cn(styles.gridItem)}
                    onClick={() => {
                      setInput(data.title);
                      ref.current.focus();
                    }}
                  >
                    <Text className={cn(styles.gridTitle)}>{data.title}</Text>
                    <Text className={cn(styles.gridSubTittle)}>
                      {data.subTitle}
                    </Text>
                    <Image src={icGuideBot} alt="icGuideBot" my={'22px'} />
                    <List>
                      {data.items.map((item: string) => (
                        <ListItem
                          className={cn(styles.gridContent)}
                          key={item}
                          textAlign={'left'}
                        >
                          {item}
                        </ListItem>
                      ))}
                    </List>
                  </Flex>
                </Box>
              ))}
            </SimpleGrid>

            <Text pt={4} className={cn(styles.guideTextDescription)}>
              Free research Previe. Chatbot may produce inaccurated information
              about people, places, or facts.{' '}
              <span>Chatbot may 12 Version.</span>
            </Text>
          </Box>
        </Box>
      )}

      <Box className={cn(styles.bottomWrapper)}>
        <Box
          className={cn(styles.inputWrapper)}
          boxShadow={{
            base: '0px -1px 0px rgba(255, 255, 255, 0.1)',
            md: 'none',
          }}
        >
          <Box px={5} maxW={'full'} mx={'auto'}>
            <InputGroup size={'lg'} className={cn(styles.inputGroup)} mt={8}>
              <InputRightElement pr={4}>
                <IconButton
                  variant="outline"
                  colorScheme="teal"
                  aria-label="Send "
                  isDisabled={loading || !input}
                  onClick={addNewChat}
                  className={cn(styles.btnSend)}
                  icon={
                    <Image as="div" alt="icon">
                      <IcSend
                        linearGradient={[
                          theme.colors.brand[600],
                          theme.colors.brand[300],
                        ]}
                      />
                    </Image>
                  }
                />
              </InputRightElement>
              <Textarea
                ref={ref}
                className={cn(styles.input)}
                variant="filled"
                placeholder="Ask me anything..."
                value={input}
                border="none"
                resize="none"
                onChange={(event) => {
                  setInput(event.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && input && !loading) {
                    e.preventDefault();
                    addNewChat();
                  }
                }}
              />
            </InputGroup>
            <Text pt={4} className={cn(styles.inputDescription)}>
              Free research Previe. Chatbot may produce inaccurated information
              about people, places, or facts.{' '}
              <span>Chatbot may 12 Version.</span>
            </Text>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

export default Home;
