import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Flex, Spinner } from '@chakra-ui/react';
import { Affix, Button } from 'antd';
import { FC, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PdfViewerProps {
  url: string;
}

const PdfViewer: FC<PdfViewerProps> = ({ url }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useState<number>(1);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setLoading(false);
    setNumPages(numPages);
  };

  const handleRoomIn = () => {
    setScale(scale + 0.5);
  };

  const handleRoomOut = () => {
    if (scale < 0.1) {
      return;
    }
    if (scale <= 1) {
      setScale(scale - 0.1);
      return;
    }
    setScale(scale - 0.5);
  };

  return (
    <>
      <Affix
        offsetTop={60}
        style={{
          position: 'fixed',
          right: 10,
          zIndex: 10,
          display: loading ? 'none' : '',
        }}
      >
        <Button onClick={handleRoomIn} icon={<ArrowsAltOutlined />} />
        <Button onClick={handleRoomOut} icon={<ShrinkOutlined />} />
      </Affix>
      {loading && (
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          color="gray"
          style={{ width: '100%', height: '100%' }}
        >
          <Spinner mr={4} /> Loading ....
        </Flex>
      )}

      <Document
        file={{ url }}
        onLoadSuccess={onDocumentLoadSuccess}
        options={{
          cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
          standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
          cMapPacked: true,
        }}
        onLoadError={(error) => {
          console.log(error);
        }}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={400}
            scale={scale}
          />
        ))}
      </Document>
    </>
  );
};
export default PdfViewer;
