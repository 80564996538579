import { colorPalette } from 'src/themes/styles';
import { ThemeName } from 'src/types/Theme.type';

export const HomeGrid = {
  example: [
    '“Explain quantum computing in simple terms”',
    'Remembers what user said earlier in the conversation',
    'May occasionally generated incorrect information',
  ],
  capabilities: [
    '“Got any creative ideas for a 10 year old’s birthday?”',
    'Allows user to provide follow-up corrections',
    'May occasionally produce harmful instructions or biased content',
  ],
  limitations: [
    '“How do i male an HTTP request in Javascript?”',
    'Trained to decline inappropriate requests',
    'Limited knowledge of world and events after 2021',
  ],
};

export const HomeGrid_V2 = [
  {
    title: '業務フロー・書面を探す',
    subTitle: '書面を表示する',
    items: [
      '・～～について教えてください',
      '・～～について業務フローを教えてください',
      '・～～に関わる書面を表示してください',
    ],
  },
  {
    title: '必要な情報を引き出す',
    subTitle: '書面を要約する',
    items: [
      '・〜〜の内容を含む書面を要約して一覧表示して下さい',
      '・〜〜の〜〜についてできるだけ細かく教えてください',
      '・〜〜の書面の要点を箇条書きで教えてください',
    ],
  },
  {
    title: '条件を付けて作成する',
    subTitle: 'プロンプトのテンプレートを使う',
    items: [
      'GPTに使えるプロンプト',
      '①SEO記事の作成',
      '②キャッチコピーの作成',
      '③小説の作成',
      '④マーケティング戦略の立案',
      '⑤プレゼンの構成案の作成',
    ],
  },
];

export const themes = () => {
  const themeOptions: {
    themeName: ThemeName;
    thumb: string[];
  }[] = [];
  for (const [key, theme] of Object.entries(colorPalette)) {
    themeOptions.push({
      themeName: key as ThemeName,
      thumb: [theme.brand[600], theme.brand[300]],
    });
  }
  return themeOptions;
};
